import { useMoralis } from "react-moralis";
import { useEffect } from 'react';
import styled from 'styled-components';

import Dice from './assets/six-sided-dice_too.svg'
import ClipLoader from "react-spinners/ClipLoader";

import Games from './components/Games'
import Profile from './components/Profile'
import House from './components/House'

const Wrapper = styled.div`
  padding: 2rem;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
`;

const Spacer = styled.div`
  height: 1rem;
`;

function App() {
  const { Moralis, authenticate, isAuthenticating, isAuthenticated, user } = useMoralis();
  // const { data, error, isLoading } = useMoralisCloudFunction("test");
  // console.log(user)
  // console.log(isAuthenticated)

  useEffect(() => {
    try {
      if (isAuthenticated) {
        (async function () {
          // * Get Parse.initialize errors if we do this w/o authentication
          // const test = await Moralis.Cloud.run("test");
          // console.log(test);
        })()
      }
    } catch (e) {
      console.log('ERROR: ', e)
    }
  },[isAuthenticated, Moralis.Cloud])

  if (isAuthenticating || (isAuthenticated && user === null)) { // TODO remove this user condition
    return (
      <Wrapper>
        <ClipLoader loading={true} />
      </Wrapper>
    )
  }

  // ? user can be null upon first authenticating
  return (
    <Wrapper>
      <h1>Ropsten Races</h1>
      <img src={Dice} alt="DICE"/>

      {!isAuthenticated ? (
        <>
          <h3>Sign in with Metamask to play</h3>
          <button onClick={authenticate}>Sign in</button>
        </>
      ) : (
        <>
          <Spacer/>
          <House/>
          <Spacer/>
          <Profile/>
          <Spacer/>
          <Games/>
        </>
      )}

    </Wrapper>
  );
}

export default App;
