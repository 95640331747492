import { useMoralis } from "react-moralis";
import { useEffect, useState } from 'react';

import { flipContractAddress } from '../constants'

function House() {
  const { web3 } = useMoralis();
  
  const [houseBalance, setHouseBalance] = useState('')
  
  useEffect(() => {
    if (web3) {
      (async function() {
        const houseBalance = await web3.eth.getBalance(flipContractAddress)
        setHouseBalance(web3.utils.fromWei(houseBalance))
      })()
    }
  }, [web3])

  return (
    <div style={{outline: '1px solid black', padding: '1rem'}}>
      <b>House Details</b>
      <p>Balance: {houseBalance} ETH</p>
      <p>Max Bet: {houseBalance / 2} ETH</p>
    </div>
  );
}

export default House;
