import { useMoralis } from "react-moralis";
import { useState } from 'react';

import { Field, Label, Input } from './shared'

function Profile() {
  const { user, setUserData, logout } = useMoralis();

  const [newUsername, setNewUsername] = useState();
  
  return (
    <div>
      <h1>Welcome {user.attributes.username}</h1>

      <Field>
        <Label>Set a new username</Label>
        <Input value={newUsername} onChange={(e) => setNewUsername(e.target.value)}/>
      </Field>

      <div>
        <button onClick={() => setUserData({
          username: newUsername
        })}>Submit</button>
        <button onClick={logout}>Logout</button>
      </div>
      
    </div>
  );
}

export default Profile;
