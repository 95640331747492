import { useMoralis } from "react-moralis";
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { flipContractAddress } from '../constants'
import { Field, Label, Input } from './shared'

const Spacer = styled.div`
  height: 1rem;
`;

function Games() {
  const { user, web3 } = useMoralis();
  
  const [ethAmount, setEthAmount] = useState('0.1');
  const [flipResult, setFlipResult] = useState(null)
  const [isAdmin, setIsAdmin] = useState(false);
  
  const flip = async (side) => {
    setFlipResult(null);
    let contractInstance = new web3.eth.Contract(window.abi, flipContractAddress)
    contractInstance.methods.flip(side === 'heads' ? 0 : 1)
      .send({value: web3.utils.toWei(ethAmount), from: window.ethereum.selectedAddress})
      .on('receipt', receipt => {
        console.log(receipt)
        setFlipResult(receipt.events.bet.returnValues.win)
      })
      .catch(e => {
        console.log(e)
      })
  }

  // Fund with 1 ETH
  const fundContract = async () => {
    let contractInstance = new web3.eth.Contract(window.abi, flipContractAddress)
    contractInstance.methods.fundContract()
      .send({value: web3.utils.toWei('1'), from: window.ethereum.selectedAddress})
      .on('receipt', receipt => {
        console.log(receipt)
      })
      .catch(e => {
        console.log(e)
      })
  }


  // Determine admin or not
  useEffect(() => {
    if (user && web3) {
      (async function () {
        const contractInstance = new web3.eth.Contract(window.abi, flipContractAddress);
        const contractOwner = await contractInstance.methods.owner().call();
        const userAccounts = user.attributes.accounts.map(a => a.toLowerCase());
        if (userAccounts.includes(contractOwner.toLowerCase())) setIsAdmin(true);
      })()
    }
  },[user, web3])

  return (
    <div>
      {isAdmin && (
        <div style={{outline: '1px solid black', padding: '1rem'}}>
          <b>Admin Panel</b>
          <Field>
            <label>Fund contract with 1 ETH: </label>
            <button onClick={fundContract}>Fund</button>
          </Field>
        </div>
      )}

      <Spacer/>
      <div style={{outline: '1px solid black', padding: '1rem'}}>
        <b>Coin Flip Game (50-50 odds to 2x)</b>
        <Field>
          <Label htmlFor="amountFlip">Place your bet (in ETH):&nbsp;</Label>
          <Input id="amountFlip" type="number" value={ethAmount} onChange={(e) => {
            setFlipResult(null);
            setEthAmount(e.target.value)
          }}/>
        </Field>
        <div>
          Choose your fate{' '}
          <button onClick={() => flip('heads')}>Heads</button>
          <button onClick={() => flip('tails')}>Tails</button>
        </div>
        <div style={{height: '3rem'}}>
          {flipResult === true ? `You won ${ethAmount} ETH!!` : flipResult === false ? `You lost!` : ''}
        </div>
      </div>

      <Spacer/>
      {/* <b>1-100 Game. Pays out 99x if correct</b>
      <div>
        <label htmlFor="amount100">Place your bet: </label>
        <input id="amount100" type="number" value={ethAmount} onChange={(e) => {
          setFlipResult(null);
          setEthAmount(e.target.value)
        }}/>ETH
      </div>
      <div>
        <label htmlFor="choice100">Choose your number: </label>
        <input id="choice100" type="number" value={ethAmount} onChange={(e) => {
          setFlipResult(null);
          setEthAmount(e.target.value)
        }}/>ETH
      </div>
      <Spacer/>
      <div>
        Choose side to flip{' '}
        <button onClick={() => flip('heads')}>Heads</button>
        <button onClick={() => flip('tails')}>Tails</button>
      </div>
      {flipResult !== null && <div>{flipResult ? `You won ${ethAmount}` : `You lost ${ethAmount}`}</div>} */}
    </div>
  );
}

export default Games;
