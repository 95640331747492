import styled from 'styled-components';


export const Spacer = styled.div`
  height: 1rem;
`;

export const Field = styled.p`
  display: flex;
  justify-content: space-between;
`;

export const Input = styled.input`
  max-width: 100px;
`;

export const Label = styled.label`

`;

